<template>
  <section>
    <a-row type="flex" class="select-user-page">
      <a-col class="company-list-wrap scroll-wrap">
        <li
          @click="changeCompany(item)"
          :class="item.id == companyId ? 'active' : ''"
          v-for="(item, key) in companyLists"
          :key="key"
        >
          <a><a-icon class="icon" type="home" />{{ item.companyName }}</a>
        </li>
      </a-col>
      <a-col class="user-list-wrap">
        <a-row class="user-list scroll-wrap">
          <a-row type="flex" :gutter="20">
            <a-col>
              <a-row>
                <!-- <a-checkbox
                  v-model="item.checked"
                  class="list-check-box"
                ></a-checkbox>
                <div class="m-b-8">{{ item.personnelName }}</div>
                <span class="decs">{{ item.jobName }}</span> -->
                <a-radio-group @change="onChange">
                  <a-radio
                    class="list-item"
                    :value="item.id"
                    v-for="(item, key) in personnelList"
                    :key="key"
                  >
                    {{ item.name }}
                  </a-radio>
                </a-radio-group>
              </a-row>
            </a-col>
          </a-row>
        </a-row>
        <!-- <a-row class="select-user-list scroll-wrap">
          <a-row type="flex" :gutter="10">
            <a-col
              :span="6"
              v-for="(item, key) in selectedPersonnelList"
              :key="key"
            >
              <a-row class="list-item">
                <div class="m-b-8">{{ item.personnelName }}</div>
                <span class="decs">{{ item.jobName }}</span>
              
              </a-row>
            </a-col>
          </a-row>
        </a-row> -->
      </a-col>
      <section class="btn-group form-btn-group">
        <a-button class="m-r-10" @click="save"
          ><a-icon type="save" />保存</a-button
        >
        <a-button @click="cancel">取消</a-button>
      </section>
    </a-row>
  </section>
</template>


<script>
export default {
  data() {
    return {
      companyLists: [],
      companyId: "",
      personnelList: []
    };
  },
  created() {
    this.queryUserOptionsDialog();
  },
  computed: {
    // selectedPersonnelList() {
    //   let r = [];
    //   this.companyLists.forEach(item => {
    //     if (item.personnel && item.personnel.length) {
    //       item.personnel.forEach(v => {
    //         if (v.checked) {
    //           r.push(v);
    //         }
    //       });
    //     }
    //   });
    //   return r;
    // }
  },
  methods: {
    queryUserOptionsDialog() {
      this.$api.queryUserOptionsDialog().then(res => {
        if (res.code === 200) {
          let companyLists = res.data || [];

          // companyLists.forEach(item => {
          //   if (item.personnel && item.personnel.length) {
          //     item.personnel.forEach(v => {
          //       v.checked = false;
          //     });
          //   }
          // });
          this.companyLists = companyLists;
          this.companyId = this.companyLists.length
            ? this.companyLists[0].id
            : "";
          this.personnelList = this.companyLists.length
            ? this.companyLists[0].user
            : [];
        }
      });
    },
    changeCompany(item) {
      this.companyId = item.id;
      this.personnelList = item.user || [];
    },
    save() {
      let data = {};
      this.companyLists.forEach(item => {
        if (item.user && item.user.length) {
          item.user.forEach(v => {
            if(this.selectPersonId==v.id){
              data=v
            }
          });
        }
      });
      if(!data.id){
        this.$message.warning('请选择人员')
        return false
      }
      this.$emit("ok", data);
    },
    cancel() {
      this.$emit("cancel", []);
    },
    // 单选框
    onChange(e) {
      this.selectPersonId = e.target.value;
      // console.log(e.target.value, this.companyLists);
    }
  }
};
</script>

<style lang="scss" scoped>
.select-user-page {
  position: relative;
  height: 510px;
  .scroll-wrap {
    overflow-y: auto;
    overflow-x: hidden;
    
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .company-list-wrap {
    width: 220px;
    height: 100%;
    border-right: 2px dotted rgba(255, 255, 255, 0.2);
    li {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 4px 8px 4px 0;
      .icon {
        margin-right: 6px;
        font-size: 14px;
      }
      a {
        color: rgba(255, 255, 255, 0.8);
      }
      &:hover {
        a {
          color: rgba(76, 132, 211, 1);
        }
      }
    }
    .active {
      a {
        color: rgba(76, 132, 211, 1);
      }
    }
  }
  .user-list-wrap {
    height: 100%;
    width: calc(100% - 220px);
    padding: 0 0 0 10px;
    .user-list {
      height: 55%;
      padding-left: 10px;
      // border-bottom: 1px dotted rgba(76, 132, 211, 0.5);
    }
    .select-user-list {
      height: 40%;
      padding: 10px 0 10px 10px;

      .list-item {
        padding: 8px 10px 10px 24px;
      }
    }
    .list-item {
      position: relative;
      border: 1px solid rgba(76, 132, 211, 0.5);
      padding: 8px 10px 10px 15px;
      border-radius: 4px;
      cursor: pointer;
      .decs {
        color: rgba(255, 255, 255, 0.5);
        font-size: 13px;
      }
      .list-check-box {
        position: absolute;
        top: 8px;
        left: 14px;
      }
    }
  }
  .btn-group {
    position: absolute;
    bottom: 0px;
    right: -20px;
    display: inline-block;
  }
}
</style>