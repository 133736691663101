<template>
    <a-row type="flex" justify="center" align="middle" :style="{height:height+'px'}">
        <div class="text-center">
            <img style="width:130px;opacity:0.8" src="../assets/image/icon/empty.png" alt="">
            <div class="m-t-10" style="color:rgba(255,255,255,0.6)">暂无数据</div>
        </div>
    </a-row>
</template>

<script>
export default {
    props:["height"],
}
</script>